<template>
  <div>
    <i @click="openMap" class="fas fa-map-marker-alt is-clickable mr-2"></i>

    <div v-if="isMapOpened" class="modal is-active">
      <div class="modal-background" @click="closeMap"></div>
      <div class="modal-content is-flex is-justify-content-center">
        <div style="max-width:100%;list-style:none; transition: none;overflow:hidden;width:80vw;height:80vh;">
          <div id="embed-ded-map-canvas" style="height:100%; width:100%;max-width:100%;">
            <iframe
              style="height:100%;width:100%;border:0;"
              frameborder="0"
              :src="`https://www.google.com/maps/embed/v1/place?q=${position.lat},${position.lon}&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8`"
            ></iframe>
          </div>
        </div>
      </div>
      <button class="modal-close is-large" aria-label="close" @click="closeMap"></button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GalleryMapIconModal',
  props: {
    position: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isMapOpened: false
    }
  },
  methods: {
    openMap () {
      this.isMapOpened = true
    },
    closeMap () {
      this.isMapOpened = false
    }
  }
}
</script>
